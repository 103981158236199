import { Menu } from 'react-admin'
import BlurOnIcon from '@mui/icons-material/BlurOn'
import CameraIcon from '@mui/icons-material/Camera'
import CableIcon from '@mui/icons-material/Cable'
import GroupIcon from '@mui/icons-material/Group'
import DevicesIcon from '@mui/icons-material/Devices'
import BarChartIcon from '@mui/icons-material/BarChart'

export const CustomMenu = () => (
  <Menu>
    <Menu.DashboardItem />
    <Menu.Item
      to="/admin-users"
      primaryText="Пользователи"
      leftIcon={<GroupIcon />}
    />
    <Menu.Item
      to="/device-list"
      primaryText="Устройства"
      leftIcon={<DevicesIcon />}
    />
    <Menu.Item
      to="/scenarios"
      primaryText="Сценарии"
      leftIcon={<CameraIcon />}
    />
    <Menu.Item to="/scenes" primaryText="Сцены" leftIcon={<BlurOnIcon />} />
    <Menu.Item
      to="/ports-config"
      primaryText="Порты"
      leftIcon={<CableIcon />}
    />
    <Menu.Item
      to="/statistics"
      primaryText="Статистика"
      leftIcon={<BarChartIcon />}
    />
  </Menu>
)
