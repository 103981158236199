import { DeviceState, ScenarioState } from '../../interfaces'
import classes from './DeviceItemTable.module.sass'
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import StopIcon from '@mui/icons-material/Stop'
import PestControlIcon from '@mui/icons-material/PestControl'
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated'
import FastForwardIcon from '@mui/icons-material/FastForward'
import useDeviceItemTableHook from './hooks/useDeviceItemTableHook'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Collapse from '@mui/material/Collapse'

type Props = {
  deviceConfig: DeviceState
  toggleDebug: (deviceId: string, value: boolean) => void
  playScene: (deviceId: string, sceneId: string) => void
  updateDeviceConfig: (deviceId: string) => void
  rebootDevice: (deviceId: string) => void
  startScenario: (deviceId: string) => void
  stopScenario: (deviceId: string) => void
  playNextScene: (deviceId: string) => void
}

export const DeviceItemTable = ({
  deviceConfig,
  toggleDebug,
  playScene,
  updateDeviceConfig,
  rebootDevice,
  startScenario,
  stopScenario,
  playNextScene,
}: Props) => {
  const {
    getScenarioName,
    getSceneName,
    activeScenes,
    open,
    setOpen,
    getDuration,
  } = useDeviceItemTableHook({
    deviceConfig,
  })

  return (
    <div className={classes.Container}>
      <Card>
        <CardHeader
          action={
            <>
              <Tooltip title="включить">
                <IconButton
                  aria-label="turnOn"
                  disabled={
                    deviceConfig.state === ScenarioState.START ||
                    deviceConfig.debugMode
                  }
                  onClick={() => startScenario(deviceConfig.deviceId)}
                >
                  <PlayArrowIcon
                    color={
                      deviceConfig.debugMode
                        ? 'disabled'
                        : deviceConfig.state === ScenarioState.STOP
                        ? 'primary'
                        : 'disabled'
                    }
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="следующая сцена">
                <IconButton
                  aria-label="turnOn"
                  disabled={deviceConfig.debugMode}
                  onClick={() => playNextScene(deviceConfig.deviceId)}
                >
                  <FastForwardIcon
                    color={
                      deviceConfig.debugMode
                        ? 'disabled'
                        : deviceConfig.isScenePlaying
                        ? 'warning'
                        : 'primary'
                    }
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="остановить">
                <IconButton
                  aria-label="turnOff"
                  disabled={deviceConfig.state === ScenarioState.STOP}
                  onClick={() => stopScenario(deviceConfig.deviceId)}
                >
                  <StopIcon
                    color={
                      deviceConfig.state === ScenarioState.START
                        ? 'primary'
                        : 'disabled'
                    }
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="режим отладки">
                <IconButton
                  aria-label="debug"
                  disabled={deviceConfig.state === ScenarioState.START}
                  onClick={() =>
                    toggleDebug(deviceConfig.deviceId, !deviceConfig.debugMode)
                  }
                >
                  <PestControlIcon
                    color={deviceConfig.debugMode ? 'error' : 'primary'}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="перезагрузить">
                <IconButton
                  aria-label="reboot"
                  onClick={() => rebootDevice(deviceConfig.deviceId)}
                >
                  <RestartAltIcon color="primary" />
                </IconButton>
              </Tooltip>
              <Tooltip title="обновить конфигурацию">
                <IconButton
                  aria-label="updateConfig"
                  onClick={() => updateDeviceConfig(deviceConfig.deviceId)}
                >
                  <BrowserUpdatedIcon color="primary" />
                </IconButton>
              </Tooltip>
            </>
          }
          title={
            <Typography color="text.primary">
              {deviceConfig.deviceName}
            </Typography>
          }
          subheader={
            <>
              <Typography
                sx={{
                  fontSize: 12,
                }}
                color="text.secondary"
                gutterBottom
              >
                состояние :{' '}
                {activeScenes.length > 0 ? (
                  deviceConfig.state === ScenarioState.START ? (
                    <span style={{ color: 'green' }}>включен</span>
                  ) : (
                    <span style={{ color: 'red' }}>выключен</span>
                  )
                ) : (
                  <span style={{ color: 'red' }}>ошибка конфигурации</span>
                )}
              </Typography>
              <Typography
                sx={{ fontSize: 12 }}
                color="text.secondary"
                gutterBottom
              >
                текущий сценарий: {getScenarioName(deviceConfig.scenarioId)}
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                }}
                color="text.secondary"
                gutterBottom
              >
                текущая сцена: {getSceneName(deviceConfig.currentScene)}
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                }}
                color="text.secondary"
                gutterBottom
              >
                дата начала:{' '}
                {deviceConfig.startTimestamp
                  ? new Date(deviceConfig.startTimestamp).toLocaleString(
                      'ru-RU',
                    )
                  : '-'}
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                }}
                color="text.secondary"
                gutterBottom
              >
                дата окончания:{' '}
                {deviceConfig.stopTimestamp
                  ? new Date(deviceConfig.stopTimestamp).toLocaleString('ru-RU')
                  : '-'}
              </Typography>
              <Typography
                sx={{
                  fontSize: 12,
                }}
                color="text.secondary"
                gutterBottom
              >
                продолжительность:{' '}
                {getDuration(
                  deviceConfig.startTimestamp,
                  deviceConfig.stopTimestamp,
                )}
              </Typography>
            </>
          }
        />
        <CardContent>
          <div className={classes.Wrapper}>
            <IconButton
              style={{ borderRadius: 15, paddingRight: 10 }}
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <>
                  <KeyboardArrowUpIcon />
                  <span className={classes.Text}>свернуть</span>
                </>
              ) : (
                <>
                  <KeyboardArrowDownIcon />
                  <span className={classes.Text}>подробнее</span>
                </>
              )}
            </IconButton>
          </div>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">название сцены</TableCell>
                    <TableCell align="right">продолжительность</TableCell>
                    {deviceConfig.debugMode && (
                      <TableCell align="right">действия</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activeScenes.map((item) => (
                    <TableRow
                      key={item.id}
                      sx={{
                        background:
                          deviceConfig.currentScene === item.id
                            ? deviceConfig.isScenePlaying
                              ? '#92f794'
                              : '#fff08f'
                            : 'transparent',
                      }}
                    >
                      <TableCell align="right">{item.name}</TableCell>
                      <TableCell align="right">{item.duration}</TableCell>
                      {deviceConfig.debugMode && (
                        <TableCell align="right">
                          <Tooltip title="проиграть сцену">
                            <IconButton
                              aria-label="turnOnScene"
                              disabled={deviceConfig.isScenePlaying}
                              onClick={() =>
                                playScene(deviceConfig.deviceId, item.id)
                              }
                            >
                              <PlayArrowIcon
                                color={
                                  deviceConfig.isScenePlaying
                                    ? 'disabled'
                                    : 'primary'
                                }
                              />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </CardContent>
      </Card>
    </div>
  )
}
