import {
  Datagrid,
  List,
  NumberField,
  TextField,
  EditButton,
  useRecordContext,
} from 'react-admin'
import { CustomPagination } from '../../common/customPagination/CustomPagination'
import config from '../../constants/config'
import { EffectsTable } from './components/effectsTable/EffectsTable'

const CustomAudioView = (props: any) => {
  const record = useRecordContext(props.source)

  if (!record.sound) return <span>отсутствует</span>

  return (
    <audio controls>
      <source
        src={config.apiUrl + '/' + record.sound.toString()}
        type="audio/mpeg"
      />
      Your browser does not support the audio element.
    </audio>
  )
}

export const ListPage = () => {
  return (
    <List title="Список сцен" empty={false} pagination={<CustomPagination />}>
      <Datagrid expand={<EffectsTable />}>
        <TextField source="name" label="Название" />
        <NumberField source="duration" label="Время работы" />
        <CustomAudioView source="sound" label="Аудио сопровождение сцены" />
        <EditButton />
      </Datagrid>
    </List>
  )
}
