import {
  ArrayInput,
  Edit,
  FileInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useRecordContext,
} from 'react-admin'
import config from '../../constants/config'

const CustomAudioView = (props: any) => {
  const record = useRecordContext(props)
  return (
    <audio controls>
      <source src={config.apiUrl + '/' + record.toString()} type="audio/ogg" />
      Your browser does not support the audio element.
    </audio>
  )
}

export const EditPage = () => {
  return (
    <Edit title="Редактирование сценария">
      <SimpleForm>
        <TextInput
          source="name"
          label="Название"
          required={true}
          parse={(value: string) => (value ? value.trim() : '')}
        />
        <FileInput source="sound" label="Фоновая музыка" accept="audio/ogg">
          <CustomAudioView source="sound" />
        </FileInput>
        <ArrayInput source="scenes" label="Эффекты" required={true}>
          <SimpleFormIterator>
            <ReferenceInput
              reference="scenes"
              source=""
              label="Эффект"
              perPage={1000}
            >
              <SelectInput optionText="name" optionValue="id" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  )
}
