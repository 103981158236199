import { useAuthState, Loading, Title } from 'react-admin'
import { redirect } from 'react-router'
import { Card, CardContent, Typography } from '@mui/material'
import classes from './Dashboard.module.sass'
import useDashboardHook from './hooks/useDashboardHook'
import { DeviceItemTable } from './components/deviceItemTable/DeviceItemTable'

export const Dashboard = () => {
  const {
    deviceStatusList,
    isDataLoading,
    toggleDebug,
    playScene,
    updateDeviceConfig,
    rebootDevice,
    startScenario,
    stopScenario,
    playNextScene,
  } = useDashboardHook()
  /** This hook is secure access to the page */
  const { isLoading, authenticated } = useAuthState()

  if (isLoading) {
    return <Loading />
  }
  if (authenticated) {
    return (
      <div className={classes.Container}>
        <Title title="Главная" />
        <Typography variant="h6" gutterBottom>
          Устройства в сети:
        </Typography>
        {isDataLoading && <Loading />}
        {!isDataLoading &&
          deviceStatusList.map((item, index) => (
            <DeviceItemTable
              deviceConfig={item}
              toggleDebug={toggleDebug}
              playScene={playScene}
              updateDeviceConfig={updateDeviceConfig}
              rebootDevice={rebootDevice}
              startScenario={startScenario}
              stopScenario={stopScenario}
              playNextScene={playNextScene}
              key={index}
            />
          ))}
        {!isDataLoading && deviceStatusList.length === 0 && (
          <Typography variant="subtitle1" gutterBottom>
            список пуст
          </Typography>
        )}
      </div>
    )
  }
  if (!authenticated) redirect('/login')
  return null
}
