import { Create, SimpleForm, TextInput, SelectInput } from 'react-admin'
import portsChoices from './constants/portsChoices'

export const CreatePage = () => {
  return (
    <Create title="Создать порт" redirect="list">
      <SimpleForm>
        <TextInput
          source="name"
          label="Название"
          required={true}
          parse={(value: string) => (value ? value.trim() : '')}
        />
        <SelectInput
          source="portNumber"
          label="Порт"
          required={true}
          choices={portsChoices}
        />
      </SimpleForm>
    </Create>
  )
}
