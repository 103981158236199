import { CustomPagination } from '../../common/customPagination/CustomPagination'
import { Datagrid, DateField, EditButton, List, TextField } from 'react-admin'

export const ListPage = () => {
  return (
    <List
      title="Списки пользователей"
      empty={false}
      pagination={<CustomPagination />}
    >
      <Datagrid>
        <TextField source="username" label="Логин" />
        <TextField source="email" label="Email" />
        <DateField
          source="registerDate"
          showTime={true}
          label="Дата регистрации"
        />
        <EditButton />
      </Datagrid>
    </List>
  )
}
