import React from 'react'
import { useGetList, Loading } from 'react-admin'
import { Card, CardContent } from '@mui/material'

import inMemoryJWT from '../../../Auth/inMemoryJWT'
import { Typography } from '@mui/material'
import { DateRangeFilter } from './DateRangeFilter'
import { DeviceListFilter } from './DeviceListFilter'

export const StatisticsFilter = () => {
  const { error, data, isLoading } = useGetList(
    'device-list',
    {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'name', order: 'ASC' },
    },
    { enabled: !!inMemoryJWT.getToken() },
  )
  if (isLoading) {
    return <Loading />
  }
  if (error) {
    return <span>error</span>
  }
  if (data) {
    return (
      <Card sx={{ order: -1, width: '15em', marginRight: '1em' }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Фильтры
          </Typography>
          <DeviceListFilter deviceList={data} />
          <DateRangeFilter />
        </CardContent>
      </Card>
    )
  }
  return null
}
