import { Edit, SimpleForm, TextInput } from 'react-admin'

export const EditPage = () => {
  return (
    <Edit title="Редактирование пользователя">
      <SimpleForm>
        <TextInput
          source="username"
          label="Логин"
          required={true}
          parse={(value: string) => (value ? value.trim() : '')}
        />
        <TextInput
          source="email"
          type="email"
          label="Email"
          required={true}
          parse={(value: string) => (value ? value.trim() : '')}
        />
      </SimpleForm>
    </Edit>
  )
}
