import { useCallback, useState } from 'react'
import { DeviceState } from '../interfaces'
import inMemoryJWT from '../../../Auth/inMemoryJWT'
import config from '../../../constants/config'
import axios from 'axios'
import useInterval from 'use-interval'

const useDashboardHook = () => {
  const [deviceStatusList, setDeviceStatusList] = useState<DeviceState[]>([])
  const [isDataLoading, setIsDataLoading] = useState(true)

  const getDeviceState = useCallback(async () => {
    try {
      const token = inMemoryJWT.getToken()
      if (!token) {
        inMemoryJWT.setRefreshTokenEndpoint(
          `${config.apiUrl}/admin-users/refresh`,
        )
        inMemoryJWT.getRefreshedToken().then()
        return
      }

      const result = await axios.get<DeviceState[]>(
        `${config.apiUrl}/device-control/getState`,
        { headers: { Authorization: `Bearer ${token}` }, timeout: 5000 },
      )

      console.log('device status list: ', result.data)
      setDeviceStatusList(result.data)
      if (isDataLoading) setIsDataLoading(false)
    } catch (error) {
      console.log('get device status error: ', error)
    }
  }, [isDataLoading])
  useInterval(getDeviceState, 1000)

  const toggleDebug = async (deviceId: string, value: boolean) => {
    try {
      const token = inMemoryJWT.getToken()
      if (!token) {
        inMemoryJWT.setRefreshTokenEndpoint(
          `${config.apiUrl}/admin-users/refresh`,
        )
        inMemoryJWT.getRefreshedToken().then()
        return
      }

      const result = await axios.post(
        `${config.apiUrl}/device-control/setDebugMode`,
        { deviceId, value },
        { headers: { Authorization: `Bearer ${token}` }, timeout: 5000 },
      )
    } catch (error) {
      console.log('toggle debug error: ', error)
    }
  }

  const playScene = async (deviceId: string, sceneId: string) => {
    try {
      const token = inMemoryJWT.getToken()
      if (!token) {
        inMemoryJWT.setRefreshTokenEndpoint(
          `${config.apiUrl}/admin-users/refresh`,
        )
        inMemoryJWT.getRefreshedToken().then()
        return
      }
      const result = await axios.post(
        `${config.apiUrl}/device-control/playScene`,
        { deviceId, sceneId },
        { headers: { Authorization: `Bearer ${token}` }, timeout: 5000 },
      )
    } catch (error) {
      console.log('play scene error: ', error)
    }
  }

  const updateDeviceConfig = async (deviceId: string) => {
    try {
      const token = inMemoryJWT.getToken()
      if (!token) {
        inMemoryJWT.setRefreshTokenEndpoint(
          `${config.apiUrl}/admin-users/refresh`,
        )
        inMemoryJWT.getRefreshedToken().then()
        return
      }
      const result = await axios.post(
        `${config.apiUrl}/device-control/updateDeviceConfig`,
        { deviceId },
        { headers: { Authorization: `Bearer ${token}` }, timeout: 5000 },
      )
    } catch (error) {
      console.log('update device config error: ', error)
    }
  }

  const rebootDevice = async (deviceId: string) => {
    try {
      const token = inMemoryJWT.getToken()
      if (!token) {
        inMemoryJWT.setRefreshTokenEndpoint(
          `${config.apiUrl}/admin-users/refresh`,
        )
        inMemoryJWT.getRefreshedToken().then()
        return
      }
      const result = await axios.post(
        `${config.apiUrl}/device-control/rebootDevice`,
        { deviceId },
        { headers: { Authorization: `Bearer ${token}` }, timeout: 5000 },
      )
    } catch (error) {
      console.log('reboot error: ', error)
    }
  }

  const startScenario = async (deviceId: string) => {
    try {
      const token = inMemoryJWT.getToken()
      if (!token) {
        inMemoryJWT.setRefreshTokenEndpoint(
          `${config.apiUrl}/admin-users/refresh`,
        )
        inMemoryJWT.getRefreshedToken().then()
        return
      }
      const result = await axios.post(
        `${config.apiUrl}/device-control/startScenario`,
        { deviceId },
        { headers: { Authorization: `Bearer ${token}` }, timeout: 5000 },
      )
    } catch (error) {
      console.log('start scenario error: ', error)
    }
  }

  const stopScenario = async (deviceId: string) => {
    try {
      const token = inMemoryJWT.getToken()
      if (!token) {
        inMemoryJWT.setRefreshTokenEndpoint(
          `${config.apiUrl}/admin-users/refresh`,
        )
        inMemoryJWT.getRefreshedToken().then()
        return
      }
      const result = await axios.post(
        `${config.apiUrl}/device-control/stopScenario`,
        { deviceId },
        { headers: { Authorization: `Bearer ${token}` }, timeout: 5000 },
      )
    } catch (error) {
      console.log('stop scenario error: ', error)
    }
  }

  const playNextScene = async (deviceId: string) => {
    try {
      const token = inMemoryJWT.getToken()
      if (!token) {
        inMemoryJWT.setRefreshTokenEndpoint(
          `${config.apiUrl}/admin-users/refresh`,
        )
        inMemoryJWT.getRefreshedToken().then()
        return
      }
      const result = await axios.post(
        `${config.apiUrl}/device-control/playNextScene`,
        { deviceId },
        { headers: { Authorization: `Bearer ${token}` }, timeout: 5000 },
      )
    } catch (error) {
      console.log('play next scene error: ', error)
    }
  }

  return {
    deviceStatusList,
    isDataLoading,
    toggleDebug,
    playScene,
    updateDeviceConfig,
    rebootDevice,
    startScenario,
    stopScenario,
    playNextScene,
  }
}

export default useDashboardHook
