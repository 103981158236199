import {
  ArrayInput,
  BooleanInput,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin'

export const EditPage = () => {
  return (
    <Edit title="Редактирование устройства">
      <SimpleForm>
        <TextInput
          source="deviceId"
          label="Id устройства"
          required={true}
          parse={(value: string) => (value ? value.trim() : '')}
        />
        <TextInput
          source="name"
          label="Название"
          required={true}
          parse={(value: string) => (value ? value.trim() : '')}
        />
        <ReferenceInput
          reference="ports-config"
          source="port"
          isRequired={true}
          perPage={1000}
        >
          <SelectInput
            optionText="name"
            optionValue="id"
            label="Порт включения сцен"
          />
        </ReferenceInput>
        <ArrayInput
          source="scenarios"
          label="Доступные сценарии"
          required={true}
        >
          <SimpleFormIterator>
            <ReferenceInput
              reference="scenarios"
              source="scenarioId"
              isRequired={true}
              perPage={1000}
            >
              <SelectInput
                optionText="name"
                optionValue="id"
                label="Сценарий"
              />
            </ReferenceInput>
            <BooleanInput
              source="isActive"
              label="Включен по умолчанию"
              defaultChecked={false}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  )
}
