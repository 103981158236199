const portsChoices = [
  {
    id: 3,
    name: 'GPIO-2',
  },
  {
    id: 5,
    name: 'GPIO-3',
  },
  {
    id: 7,
    name: 'GPIO-4',
  },
  {
    id: 8,
    name: 'GPIO-14',
  },
  {
    id: 10,
    name: 'GPIO-15',
  },
  {
    id: 11,
    name: 'GPIO-17',
  },
  {
    id: 12,
    name: 'GPIO-18 (PWM)',
  },
  {
    id: 13,
    name: 'GPIO-27',
  },
  {
    id: 15,
    name: 'GPIO-22',
  },
  {
    id: 16,
    name: 'GPIO-23',
  },
  {
    id: 18,
    name: 'GPIO-24',
  },
  {
    id: 19,
    name: 'GPIO-10',
  },
  {
    id: 21,
    name: 'GPIO-9',
  },
  {
    id: 22,
    name: 'GPIO-25',
  },
  {
    id: 23,
    name: 'GPIO-11',
  },
  {
    id: 24,
    name: 'GPIO-8',
  },
  {
    id: 26,
    name: 'GPIO-7',
  },
  {
    id: 27,
    name: 'GPIO-0',
  },
  {
    id: 28,
    name: 'GPIO-1',
  },
  {
    id: 29,
    name: 'GPIO-5',
  },
  {
    id: 31,
    name: 'GPIO-6',
  },
  {
    id: 32,
    name: 'GPIO-12 (PWM)',
  },
  {
    id: 33,
    name: 'GPIO-13 (PWM)',
  },
  {
    id: 35,
    name: 'GPIO-19 (PWM)',
  },
  {
    id: 36,
    name: 'GPIO-16',
  },
  {
    id: 37,
    name: 'GPIO-26',
  },
  {
    id: 38,
    name: 'GPIO-20',
  },
  {
    id: 40,
    name: 'GPIO-21',
  },
]

export default portsChoices
