import {
  ArrayField,
  BooleanField,
  Datagrid,
  ReferenceField,
  TextField,
} from 'react-admin'

export const ScenariosTable = () => {
  return (
    <ArrayField source="scenarios" label="сценарии">
      <Datagrid bulkActionButtons={false}>
        <ReferenceField
          source="scenarioId"
          reference="scenarios"
          label="Название сценария"
        >
          <TextField source="name" />
        </ReferenceField>
        <BooleanField
          source="isActive"
          label="Включен по умолчанию"
          looseValue={true}
        />
      </Datagrid>
    </ArrayField>
  )
}
