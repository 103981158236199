import { Edit, SelectInput, SimpleForm, TextInput } from 'react-admin'
import portsChoices from './constants/portsChoices'

export const EditPage = () => {
  return (
    <Edit title="Редактирование порт">
      <SimpleForm>
        <TextInput
          source="name"
          label="Название"
          required={true}
          parse={(value: string) => (value ? value.trim() : '')}
        />
        <SelectInput
          source="portNumber"
          label="Порт"
          required={true}
          choices={portsChoices}
        />
      </SimpleForm>
    </Edit>
  )
}
