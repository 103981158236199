import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  SimpleFormIterator,
  ArrayInput,
  FileInput,
  FileField,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
} from 'react-admin'

export const CreatePage = () => {
  return (
    <Create title="Создать сцену" redirect="list">
      <SimpleForm>
        <TextInput
          source="name"
          label="Название"
          required={true}
          parse={(value: string) => (value ? value.trim() : '')}
        />
        <NumberInput
          source="duration"
          label="Время работы"
          required={true}
          helperText="в мс"
        />
        <FileInput
          source="sound"
          label="Аудио сопровождение сцены"
          accept="audio/ogg"
        >
          <FileField source="src" title="title" />
        </FileInput>
        <ArrayInput source="effects" label="Эффекты" required={true}>
          <SimpleFormIterator>
            <ReferenceInput
              reference="ports-config"
              source="port"
              perPage={1000}
              isRequired={true}
            >
              <SelectInput optionText="name" optionValue="id" label="Порт" />
            </ReferenceInput>
            <NumberInput
              source="defaultValue"
              label="Начальное состояние порта"
              defaultValue={0}
              helperText="0 или 1"
              required={true}
            />
            <NumberInput
              source="startDelay"
              label="Задержка включения"
              defaultValue={0}
              helperText="0 если не нужна, в мс"
              required={true}
            />
            <NumberInput
              source="duration"
              label="Время работы"
              defaultValue={0}
              helperText="0 если совпадает со временем работы сцены, в мс"
              required={true}
            />
            <BooleanInput
              source="backToDefault"
              label="Вернуть к начальному состоянию"
              defaultValue={1}
              helperText="возвращает порт к состоянию по умолчанию в конце сцены"
            />
            <BooleanInput
              source="pwmEnabled"
              label="ШИМ"
              defaultChecked={false}
            />
            <FormDataConsumer>
              {(formData: any, ...rest) =>
                formData.scopedFormData.pwmEnabled && (
                  <NumberInput
                    {...rest}
                    source="pwmRange"
                    label="Максимальная ширина импульса"
                    defaultValue={0}
                    helperText="пример: 1023"
                    required={true}
                  />
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {(formData: any, ...rest) =>
                formData.scopedFormData.pwmEnabled && (
                  <NumberInput
                    {...rest}
                    source="pwmClockDiv"
                    label="Делитель частоты"
                    defaultValue={8}
                    helperText="базовая частота 19.2 MHz, 8 = 2.4 MHz"
                    required={true}
                  />
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {(formData: any, ...rest) =>
                formData.scopedFormData.pwmEnabled && (
                  <NumberInput
                    {...rest}
                    source="pwmInterval"
                    label="Интервал пульсаций"
                    defaultValue={0}
                    helperText="для плавного выставления целевого значения, 0 - выключено"
                    required={true}
                  />
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {(formData: any, ...rest) =>
                formData.scopedFormData.pwmEnabled && (
                  <NumberInput
                    {...rest}
                    source="pwmData"
                    label="Целевое значение"
                    defaultValue={0}
                    required={true}
                  />
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {(formData: any, ...rest) =>
                formData.scopedFormData.pwmEnabled && (
                  <NumberInput
                    {...rest}
                    source="pwmTimes"
                    label="Кол-во пульсаций до выключения"
                    defaultValue={0}
                    helperText="0 - выключено, нужно расчитать исходя из целевого значения и интервала пульсации"
                    required={true}
                  />
                )
              }
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  )
}
