import {
  Datagrid,
  List,
  TextField,
  EditButton,
  useRecordContext,
  ReferenceArrayField,
  ShowButton,
} from 'react-admin'
import { CustomPagination } from '../../common/customPagination/CustomPagination'
import config from '../../constants/config'

const CustomAudioView = (props: any) => {
  const record = useRecordContext(props.source)

  if (!record.sound) return <span>отсутствует</span>

  return (
    <audio controls>
      <source
        src={config.apiUrl + '/' + record.sound.toString()}
        type="audio/ogg"
      />
      Your browser does not support the audio element.
    </audio>
  )
}

export const ListPage = () => {
  return (
    <List
      title="Список сценариев"
      empty={false}
      pagination={<CustomPagination />}
    >
      <Datagrid>
        <TextField source="name" label="Название" />
        <CustomAudioView source="sound" label="Фоновая музыка" />
        <ReferenceArrayField reference="scenes" source="scenes" label="Сцены">
          <Datagrid bulkActionButtons={false}>
            <TextField source="name" label="Название" />
            <TextField source="duration" label="Время работы" />
            <ShowButton />
          </Datagrid>
        </ReferenceArrayField>
        <EditButton />
      </Datagrid>
    </List>
  )
}
