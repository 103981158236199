import {
  Datagrid,
  List,
  TextField,
  EditButton,
  ReferenceField,
} from 'react-admin'
import { ScenariosTable } from './components/scenariosTable/ScenariosTable'
import { CustomPagination } from '../../common/customPagination/CustomPagination'

export const ListPage = () => {
  return (
    <List
      title="Список устройств"
      empty={false}
      pagination={<CustomPagination />}
    >
      <Datagrid expand={<ScenariosTable />}>
        <TextField source="deviceId" label="Id устройства" />
        <TextField source="name" label="Название" />
        <ReferenceField
          source="port"
          reference="ports-config"
          label="Порт включения сцен"
        >
          <TextField source="name" />
        </ReferenceField>
        <EditButton />
      </Datagrid>
    </List>
  )
}
