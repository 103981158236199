import { Datagrid, List, TextField, EditButton, SelectField } from 'react-admin'
import { CustomPagination } from '../../common/customPagination/CustomPagination'
import portsChoices from './constants/portsChoices'

export const ListPage = () => {
  return (
    <List title="Список портов" empty={false} pagination={<CustomPagination />}>
      <Datagrid>
        <TextField source="name" label="Название" />
        <SelectField source="portNumber" label="Порт" choices={portsChoices} />
        <EditButton />
      </Datagrid>
    </List>
  )
}
