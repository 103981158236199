import React from 'react'
import { Admin, Resource, CustomRoutes } from 'react-admin'
import { Route } from 'react-router-dom'
import myDataProvider from './dataProvider/myDataProvider'
import polyglotI18nProvider from 'ra-i18n-polyglot'
// @ts-ignore
import russianMessages from 'ra-language-russian'
import { CustomLayout } from './layout/CustomLayout'
import scenes from './containers/scenes'
import scenarios from './containers/scenarios'
import portsConfig from './containers/portsConfig'
import authProvider from './Auth/authProvider'
import adminUsers from './containers/adminUsers'
import deviceList from './containers/deviceList'
import statistics from './containers/statistics'
import { Dashboard } from './containers/dashboard/Dashboard'

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru')

const App = () => {
  const dataProvider = myDataProvider()

  return (
    <Admin
      title="Панель управления"
      dashboard={Dashboard}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      layout={CustomLayout}
      disableTelemetry
    >
      <Resource
        name="scenarios"
        list={scenarios.list}
        create={scenarios.create}
        edit={scenarios.edit}
      />
      <Resource
        name="scenes"
        list={scenes.list}
        create={scenes.create}
        edit={scenes.edit}
      />
      <Resource
        name="ports-config"
        list={portsConfig.list}
        create={portsConfig.create}
        edit={portsConfig.edit}
      />
      <Resource
        name="admin-users"
        list={adminUsers.list}
        create={adminUsers.create}
        edit={adminUsers.edit}
      />
      <Resource
        name="device-list"
        list={deviceList.list}
        create={deviceList.create}
        edit={deviceList.edit}
      />
      <Resource name="statistics" list={statistics.list} />
      {/*<CustomRoutes>*/}
      {/*  <Route path="/dashboard" element={<Dashboard />} />*/}
      {/*</CustomRoutes>*/}
    </Admin>
  )
}

export default App
