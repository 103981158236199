import {
  Datagrid,
  List,
  TextField,
  ReferenceField,
  DateField,
} from 'react-admin'
import { CustomPagination } from '../../common/customPagination/CustomPagination'
import { StatisticsFilter } from './filters/StatisticsFilter'

export const ListPage = () => {
  return (
    <List
      title="Статистика"
      empty={false}
      pagination={<CustomPagination />}
      sort={{ field: 'stopTimestamp', order: 'DESC' }}
      aside={<StatisticsFilter />}
    >
      <Datagrid>
        <ReferenceField
          source="deviceId"
          reference="device-list"
          label="Устройство"
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField
          source="startTimestamp"
          label="Дата начала"
          showTime={true}
        />
        <DateField
          source="stopTimestamp"
          label="Дата окончания"
          showTime={true}
        />
        <TextField source="duration" label="Продолжительность" />
      </Datagrid>
    </List>
  )
}
