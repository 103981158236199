import {
  Create,
  SimpleForm,
  TextInput,
  SimpleFormIterator,
  ArrayInput,
  FileInput,
  FileField,
  ReferenceInput,
  SelectInput,
} from 'react-admin'

export const CreatePage = () => {
  return (
    <Create title="Создать сценарий" redirect="list">
      <SimpleForm>
        <TextInput
          source="name"
          label="Название"
          required={true}
          parse={(value: string) => (value ? value.trim() : '')}
        />
        <FileInput source="sound" label="Фоновая музыка" accept="audio/ogg">
          <FileField source="src" title="title" />
        </FileInput>
        <ArrayInput source="scenes" label="Эффекты" required={true}>
          <SimpleFormIterator>
            <ReferenceInput
              reference="scenes"
              source=""
              label="Эффект"
              perPage={1000}
            >
              <SelectInput optionText="name" optionValue="id" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  )
}
