import { ListPage } from './ListPage'
import { EditPage } from './EditPage'
import { CreatePage } from './CreatePage'

const pages = {
  list: ListPage,
  edit: EditPage,
  create: CreatePage,
}

export default pages
