import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { FilterList, FilterListItem } from 'react-admin'
import {
  startOfYesterday,
  endOfYesterday,
  startOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
} from 'date-fns'

export const DateRangeFilter = () => (
  <FilterList label="Дата" icon={<AccessTimeIcon />}>
    <FilterListItem
      label="Сегодня"
      value={{
        last_seen_gte: endOfYesterday().toISOString(),
        last_seen_lte: undefined,
      }}
    />
    <FilterListItem
      label="Вчера"
      value={{
        last_seen_gte: startOfYesterday().toISOString(),
        last_seen_lte: endOfYesterday().toISOString(),
      }}
    />
    <FilterListItem
      label="Эта неделя"
      value={{
        last_seen_gte: startOfWeek(new Date(), {
          weekStartsOn: 1,
        }).toISOString(),
        last_seen_lte: undefined,
      }}
    />
    <FilterListItem
      label="Прошлая неделя"
      value={{
        last_seen_gte: subWeeks(
          startOfWeek(new Date(), { weekStartsOn: 1 }),
          1,
        ).toISOString(),
        last_seen_lte: startOfWeek(new Date(), {
          weekStartsOn: 1,
        }).toISOString(),
      }}
    />
    <FilterListItem
      label="Этот месяц"
      value={{
        last_seen_gte: startOfMonth(new Date()).toISOString(),
        last_seen_lte: undefined,
      }}
    />
    <FilterListItem
      label="Прошлый месяц"
      value={{
        last_seen_gte: subMonths(startOfMonth(new Date()), 1).toISOString(),
        last_seen_lte: startOfMonth(new Date()).toISOString(),
      }}
    />
  </FilterList>
)
