import React from 'react'
import { FilterList, FilterListItem } from 'react-admin'
import DevicesIcon from '@mui/icons-material/Devices'

export const DeviceListFilter = ({ deviceList }: any) => {
  return (
    <FilterList label="Устройство" icon={<DevicesIcon />}>
      {deviceList &&
        deviceList.map(
          (item: {
            name:
              | string
              | React.ReactElement<
                  any,
                  string | React.JSXElementConstructor<any>
                >
            id: React.Key | null | undefined
          }) => (
            <FilterListItem
              label={item.name}
              value={{
                deviceId: item.id,
              }}
              key={item.id}
            />
          ),
        )}
    </FilterList>
  )
}
