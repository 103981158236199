import { DeviceState, SceneInterface } from '../../../interfaces'
import { useEffect, useState } from 'react'

type Props = {
  deviceConfig: DeviceState
}

const useDeviceItemTableHook = ({ deviceConfig }: Props) => {
  const [activeScenes, setActiveScenes] = useState<SceneInterface[]>([])
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const scenario = deviceConfig.scenariosList.find(
      (item) => item.id === deviceConfig.scenarioId,
    )
    if (!scenario) return

    const scenesList = scenario.scenes
      .map((item) => deviceConfig.scenesList.find((scene) => scene.id === item))
      .filter((sceneItem) => sceneItem)

    // @ts-ignore
    setActiveScenes(scenesList)
  }, [
    deviceConfig.scenarioId,
    deviceConfig.scenariosList,
    deviceConfig.scenesList,
  ])

  const getScenarioName = (scenarioId: string): string => {
    const scenario = deviceConfig.scenariosList.find(
      (item) => item.id === scenarioId,
    )
    return scenario ? scenario.name : '-'
  }

  const getSceneName = (sceneId: string): string => {
    const scene = deviceConfig.scenesList.find((item) => item.id === sceneId)
    return scene ? scene.name : '-'
  }

  const getDuration = (start: number, end: number): string => {
    if (!start) return '00:00:00'
    const startDate = start
    const endDate = end ? end : new Date().getTime()
    const diffMs = endDate - startDate

    const diffSeconds = Math.floor(Math.abs(diffMs) / 1000)
    const seconds =
      diffSeconds % 60 < 10 ? `0${diffSeconds % 60}` : String(diffSeconds % 60)
    const diffMinutes = Math.floor(diffSeconds / 60)
    const minutes =
      diffMinutes % 60 < 10 ? `0${diffMinutes % 60}` : String(diffMinutes % 60)
    const diffHrs = Math.floor(diffMinutes / 60)
    const hours = diffHrs < 10 ? `0${diffHrs}` : String(diffHrs)
    // const hours = diffHrs % 24 < 10 ? `0${diffHrs % 24}` : String(diffHrs % 24)

    return `${hours}:${minutes}:${seconds}`
  }

  return {
    getScenarioName,
    getSceneName,
    activeScenes,
    open,
    setOpen,
    getDuration,
  }
}

export default useDeviceItemTableHook
