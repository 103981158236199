export enum ScenarioState {
  STOP,
  START,
}

interface PortState {
  portId: string
  portNumber: number
  defaultValue: number
  currentState: number
}

export interface ScenarioInterface {
  id: string
  name: string
  sound: string
  scenes: string[]
}

interface EffectInterface {
  port: string
  defaultValue: number
  startDelay: number
  duration: number
  pwmEnabled: boolean
  pwmRange: number
  pwmClockDiv: number
  pwmInterval: number
  pwmData: number
  pwmTimes: number
}

export interface SceneInterface {
  id: string
  name: string
  duration: number
  sound: string
  effects: EffectInterface[]
}

export interface DeviceState {
  deviceId: string
  deviceName: string
  scenariosList: ScenarioInterface[]
  scenesList: SceneInterface[]
  scenarioId: string
  state: ScenarioState
  currentScene: string
  startTimestamp: number
  stopTimestamp: number
  debugMode: boolean
  isScenePlaying: boolean
  portsState: PortState[]
}
