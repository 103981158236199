import {
  ArrayField,
  BooleanField,
  Datagrid,
  NumberField,
  ReferenceField,
  TextField,
} from 'react-admin'

export const EffectsTable = () => {
  return (
    <ArrayField source="effects" label="Эффекты">
      <Datagrid bulkActionButtons={false}>
        <ReferenceField source="port" reference="ports-config" label="Порт">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="defaultValue" label="Начальное состояние порта" />
        <NumberField source="startDelay" label="Задержка включения" />
        <NumberField source="duration" label="Время работы" />
        <BooleanField
          source="backToDefault"
          label="Вернуть к начальному состоянию"
          looseValue={true}
        />
        <BooleanField source="pwmEnabled" label="ШИМ" looseValue={true} />
        <NumberField source="pwmRange" label="Максимальная ширина импульса" />
        <NumberField source="pwmClockDiv" label="Делитель частоты" />
        <NumberField source="pwmInterval" label="Интервал пульсаций" />
        <NumberField source="pwmData" label="Целевое значение" />
        <NumberField source="pwmTimes" label="Кол-во пульсаций до выключения" />
      </Datagrid>
    </ArrayField>
  )
}
